import moment from "moment";
import get from "lodash.get";
import * as pdfjsLib from "pdfjs-dist";
import * as FileSaver from "file-saver";
import cloneDeep from "lodash/cloneDeep";
import * as lodashIsEmpty from "lodash.isempty";

import notificationService from "./notification.service";
import LocalStorageService from "./local-storage.service";
import { getReportDateFilter } from "./date-time.service";
import { LOGOUT_KEYS, supportedFileFormat } from "../constants/constant";
import {
  AUTHENTICATED_ROUTES,
  UNAUTHENTICATED_ROUTES,
} from "../routes/constant";
import { queryClient } from "../NextApp";

const utilService = {
  getLoginUrl,
  defaultUrl: AUTHENTICATED_ROUTES.DASHBOARD,
  baseUrl: process.env.REACT_APP_BASE_URL,
  apiUrl: process.env.REACT_APP_API_URL,
  graphqlApiUrl: process.env.REACT_APP_GRAPHQL,
  toNumber,
  getValue,
  redirectToLogin,
  redirectTo,
  redirectToReturnUrl,
  getUrlParameterByName,
  isEmpty,
  getQueryParams,
  getUrlParam,
  replaceNullWithPlaceholder,
  toPercentage,
  exportToFile,
  formatDuration,
  toUsPhoneNumber,
  toDollar,
  currencyFormat,
  getRoute,
  getMinutesFromTZ,
  removeNumberMasking,
  capitalizedString,
  getKeyByValue,
  removeLocalStorageRedirectLogin,
  createDynamicUrl,
  createURL,
  createSecureURL,
  toDeepCopy,
  formatAddress,
  removePhonMasking,
  formatNumberWithCommas,
  abbreviateMonth,
  dateFilter,
  millisecondsToMinutes,
  copyValue,
};

function toDeepCopy(obj) {
  return cloneDeep(obj);
}

function copyValue(value) {
  navigator.clipboard
    .writeText(value)
    .then(() => {
      notificationService.success("Copied to clipboard");
    })
    .catch(() => {
      notificationService.error("Unable to copy");
    });
}

function formatAddress(address = {}, emptyText = "") {
  let addrs = "";
  if (!isEmpty(address)) {
    addrs += address.streetAddress ? address.streetAddress.trim() + " " : "";
    addrs += address.suite ? address.suite + " " : "";
    address.city = address.city ? address.city.toLowerCase() : "";
    address.city = address.city
      ? address.city.replace(/(?:^|\s)\w/g, function (match) {
          return match.toUpperCase();
        })
      : address.city;
    if (!address.streetAddress.includes(address.zipCode)) {
      addrs +=
        (address.zipCode || address.zip) && address.state
          ? "" + (address.zipCode || address.zip)
          : (address.zipCode || address.zip) && address.city && !address.state
          ? " " + (address.zipCode || address.zip)
          : "";
    }
  } else {
    addrs = emptyText;
  }
  return addrs;
}

function removePhonMasking(number) {
  if (!number) return number;
  return ("" + number).replace(/\D/g, "");
}

function createDynamicUrl(dynamicUrl, object) {
  for (const key in object) {
    if (object[key]?.length > 0 && key == "topicIds") {
      let value = ``;
      for (const key2 in object[key]) {
        if (key2 != object[key].length - 1) {
          value += `${object[key][key2]}&${key}=`;
        } else {
          value += `${object[key][key2]}`;
        }
      }
      dynamicUrl = dynamicUrl.replace(`{${key}}`, value);
    } else {
      dynamicUrl = dynamicUrl.replace(`{${key}}`, object[key]);
    }
  }
  return dynamicUrl;
}

function removeLocalStorageRedirectLogin() {
  for (let key of LOGOUT_KEYS) {
    LocalStorageService.remove(key);
  }
  redirectToLogin();
}
function getLoginUrl() {
  return UNAUTHENTICATED_ROUTES.LOGIN;
}

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

function formatDuration(milliseconds) {
  const duration = moment.duration(milliseconds);
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  if (hours > 0) {
    return `${hours}h. ${minutes}m.`;
  } else if (minutes > 0) {
    return `${minutes}m. ${seconds}sec.`;
  } else {
    return `${seconds}sec.`;
  }
}
function millisecondsToMinutes(milliseconds) {
  const duration = moment.duration(milliseconds);
  return duration.asMinutes();
}

function formatNumberWithCommas(number) {
  if (!number) {
    return 0;
  }
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function capitalizedString(value, placeholder = "--") {
  if (!value) {
    return placeholder;
  }
  let valueArray = value.split(" ");
  valueArray = valueArray?.map((item) => {
    return item.charAt(0).toUpperCase() + item.slice(1);
  });
  return valueArray.join(" ");
}

function removeNumberMasking(number) {
  if (!number) return number;
  return ("" + number).replace(/\D/g, "");
}
function getMinutesFromTZ(timeZone) {
  if (timeZone == undefined) {
    return 0;
  }
  const timeZoneMinutes = timeZone.minutes ? timeZone.minutes : 0;
  const minutes = timeZone.hours * 60 + timeZoneMinutes;
  return minutes;
}

function toDollar(number) {
  let formattedValue;
  var negativeSignFlag = false;
  if (!number) return "$0.00";
  if (number < 0) {
    negativeSignFlag = true;
  }

  formattedValue = currencyFormat(Math.abs(number), 2, 3, ",", ".");
  if (negativeSignFlag) return "$ -" + formattedValue;
  else return "$" + formattedValue;
}

function currencyFormat(number, n, x, s, c) {
  const re = `\\d(?=(\\d{${x || 3}})+${n > 0 ? "\\D" : "$"})`;
  const num = number.toFixed(Math.max(0, ~~n));

  return (c ? num.replace(".", c) : num).replace(
    new RegExp(re, "g"),
    `$&${s || ","}`
  );
}

function exportToFile(data, fileName, type) {
  var fileMeta = fileExtensions[type];
  const response = new Blob([data], { type: fileMeta.type });
  FileSaver.saveAs(response, fileName + fileMeta.extension);
}

function abbreviateMonth(monthName) {
  const validMonths = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  if (validMonths.includes(monthName)) {
    return monthName.slice(0, 3);
  }
  return monthName;
}

function toPercentage(number) {
  if (number) return `${number}%`;
  return "--";
}

function toUsPhoneNumber(number, extension = "") {
  if (isNaN(number)) {
    return number;
  }
  let num = ("" + number).replace(/\D/g, "");
  let output;
  if (num.length > 10) {
    output = number;
  } else {
    let m = num.match(/^(\d{3})(\d{3})(\d{1,4})$/);
    output = !m ? num : "(" + m[1] + ") " + m[2] + "-" + m[3];
  }
  return extension
    ? output + ", Ext. " + ("" + extension).replace(/\D/g, "")
    : output;
}

function replaceNullWithPlaceholder(value, placeHolder = "--") {
  if (!value) {
    return placeHolder;
  }
  return value;
}

function dateFilter(id, onDateSearch) {
  const date = getReportDateFilter(id);
  onDateSearch(date?.fromDate, date?.toDate);
}

function getValue(...param) {
  return get(...param);
}

function redirectToLogin(loginUrl = getLoginUrl()) {
  let returnUrl = encodeURIComponent(
    window.location.pathname.replace(/[//]+/, "") + window.location.search
  );
  utilService.redirectTo(loginUrl + "?returnUrl=" + returnUrl);
}

function redirectTo(url) {
  window.location.href = utilService.baseUrl + url;
}

function redirectToReturnUrl() {
  utilService.redirectTo(
    utilService.getUrlParameterByName("returnUrl")
      ? "/" + utilService.getUrlParameterByName("returnUrl")
      : utilService.defaultUrl
  );
}

function getUrlParameterByName(name) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  const regexS = `[\\?&]${name}=([^&#]*)`;
  const regex = new RegExp(regexS);
  const results = regex.exec(window.location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

function isEmpty(value) {
  return lodashIsEmpty(value);
}

function numberFormat(number, n, x, s, c) {
  const re = `\\d(?=(\\d{${x || 3}})+${n > 0 ? "\\D" : "$"})`;
  const num = number.toFixed(Math.max(0, ~~n));

  return (c ? num.replace(".", c) : num).replace(
    new RegExp(re, "g"),
    `$&${s || ","}`
  );
}

function toNumber(input, decimal = 2) {
  if (input && !isNaN(input)) {
    return numberFormat(input, decimal, 3);
  }
  return input;
}

function getQueryParams(query, param) {
  let params = new URLSearchParams(query);
  return params.get(param);
}

function getUrlParam(urlParam, search) {
  return new URLSearchParams(urlParam).get(search);
}

const fileExtensions = {
  excel: {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    extension: ".xlsx",
  },
  csv: {
    type: "text/csv",
    extension: ".csv",
  },
};

function getRoute(route, obj = {}) {
  if (Object.keys(obj).length) {
    let objectKeys = Object.keys(obj);
    objectKeys.forEach((item) => {
      route = route.replace(new RegExp(/:([\d\w])+/, "i"), (match) => {
        let formattedMatchedValue = match.slice(1);
        return obj[formattedMatchedValue];
      });
    });
    return route;
  }
  return route;
}
function createURL(str) {
  const index = str.toUpperCase().indexOf("HTTP");
  if (index == -1) {
    return `http://${str}`;
  }
  return str;
}
function createSecureURL(str) {
  const index = str.toUpperCase().indexOf("HTTP");
  if (index == -1) {
    return `https://${str}`;
  }
  return str;
}
export function uploadOptions(
  setFile,
  hasMultiple = false,
  supportedFormats = supportedFileFormat,
  mbValidation = false
) {
  return {
    showUploadList: hasMultiple,
    ...(!hasMultiple && { maxCount: 1 }),
    multiple: hasMultiple,
    customRequest: (options) => {
      const { onSuccess } = options;
      setTimeout(() => onSuccess && onSuccess("ok"));
    },
    accept: "image/png,image/jpeg,image/jpg,image/jfif,image/svg",

    beforeUpload(file) {
      if (!supportedFormats.includes(file.type)) {
        notificationService.error("Unsupported file format.");
        return Promise.reject();
      } else if (mbValidation) {
        const fileSizeLimit = file.size / 1024 / 1024 < 2;
        if (!fileSizeLimit) {
          notificationService.error(`File must be smaller than ${2}MB!`);
          return Promise.reject();
        }
      }
    },
    onChange: (info, key) => {
      const { file, fileList } = info;
      if (file.status === "done" || file.status === "removed") {
        setFile((prev) => {
          return { ...prev, [key]: hasMultiple ? fileList : file };
        });
      }
    },
  };
}

export function capitalizeFirstLetter(str, locale = navigator.language) {
  return str.replace(/^\p{CWU}/u, (char) => char.toLocaleUpperCase(locale));
}

export async function getFirstPageImageFromPdf(file) {
  pdfjsLib.GlobalWorkerOptions.workerSrc =
    "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.5.207/pdf.worker.min.js";
  const loadingTask = pdfjsLib.getDocument(URL.createObjectURL(file));

  try {
    const pdf = await loadingTask.promise;

    const pageNumber = 1;
    const page = await pdf.getPage(pageNumber);

    const viewport = page.getViewport({ scale: 1.5 });

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    canvas.width = viewport.width;
    canvas.height = viewport.height;

    const renderContext = {
      canvasContext: context,
      viewport: viewport,
    };
    await page.render(renderContext).promise;

    const imageBlob = await new Promise((resolve) => {
      canvas.toBlob(resolve, "image/jpeg");
    });

    const imageFile = new File([imageBlob], "first_page.jpg", {
      type: "image/jpeg",
    });

    const imageUrl = URL.createObjectURL(imageBlob);

    return { imageUrl, imageFile };
  } catch (error) {
    console.error("Error loading PDF:", error);
    return null;
  }
}

let timer;
export function debounce(func, timeout = 1500) {
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export default utilService;
