import React from "react";
import dateTimeService from "./../../../util/date-time.service";
import { Badge, Dropdown, Menu, Space, Tooltip } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import "./index.less";
import {
  ACTIVITY_ACTIONS,
  ADMINISTRATOR_STATUS_ACTION,
  ACTIVITY_TYPE,
  REPORT_TYPE,
  FREEBIE_ACTIONS,
  RESOURCE_ACTIONS,
  CHAT_ACTIONS,
} from "../../../constants/constantTypes";
import utilService from "../../../util/utils.service";

const { formatDateToSpecificTZ, DATE_TIME_FORMAT } = dateTimeService;

export const renderDate = (date, MINUTES) => {
  if (!date) {
    return "--";
  }
  return `${formatDateToSpecificTZ(
    date,
    DATE_TIME_FORMAT.US_DATE_TIME_FORMAT,
    MINUTES
  )} ${date && "CST"}`;
};

export const renderAction = (record, onActionClick) => {
  if (!record?.actions || record?.actions?.length === 0) {
    return;
  } else if (record.actions.length) {
    return (
      <Dropdown
        overlay={
          <Menu>
            {record?.actions?.map((key, index) => {
              return (
                <Menu.Item
                  onClick={() => {
                    onActionClick(key.code, record);
                  }}
                  key={key.code}
                >
                  {key.name}
                </Menu.Item>
              );
            })}
          </Menu>
        }
        placement="bottomRight"
      >
        <div className="circle-icon" align="center">
          <MoreOutlined className="circle-icon-content" />
        </div>
      </Dropdown>
    );
  }
};

export const renderReason = (selectedRecord, type) => {
  if (type) {
    return (
      <Tooltip title={selectedRecord?.reason}>
        <p style={{ color: "#038fdd" }}>Other</p>
      </Tooltip>
    );
  } else {
    return (
      <p className={`description-ellipsis`} title={selectedRecord?.reason}>
        {selectedRecord?.reason}
      </p>
    );
  }
};

export const contentTitle = (record) => {
  return (
    <div>
      <h5 className="title-ellipsis" title={record?.headline}>
        {record?.headline}
      </h5>
      <p className="description-ellipsis" title={record?.description}>
        {record?.description}
      </p>
    </div>
  );
};

export const reportInfoDetails = (record, setDrawerInfo) => {
  const { reportDetails } = record;
  const [selectedRecord, ...rest] = reportDetails;
  const isOtherType = selectedRecord?.reportType === REPORT_TYPE.OTHER;
  return (
    <div>
      <Space>
        <h5 className="title-ellipsis" title={selectedRecord?.reporterName}>
          {selectedRecord?.reporterName}
        </h5>
        <Badge
          className="cursor"
          size="small"
          count={reportDetails?.length}
          onClick={() => setDrawerInfo(record)}
        />
      </Space>
      {renderReason(selectedRecord, isOtherType)}
    </div>
  );
};

export const _STATUS = {
  [ACTIVITY_ACTIONS.APPROVED]: {
    title: (title) =>
      `Approved ${utilService.replaceNullWithPlaceholder(title)}`,
    description: () => "Are you sure you want to approve this activity?",
    buttonText: "Approve",
    className: "confirm-button",
    toggle: false,
  },
  [ACTIVITY_ACTIONS.REJECTED]: {
    title: (title) => `Reject ${utilService.replaceNullWithPlaceholder(title)}`,
    description: () => "Are you sure you want to reject this activity?",
    buttonText: "Reject",
    className: "warn-button",
    toggle: false,
  },
  [ACTIVITY_ACTIONS.DELETE]: {
    title: (title) => `Delete ${utilService.replaceNullWithPlaceholder(title)}`,
    description: (title) =>
      `Are you sure you want to delete ${utilService.replaceNullWithPlaceholder(
        title
      )}?`,
    buttonText: "Delete",
    className: "warn-button",
    toggle: true,
  },
  [ACTIVITY_ACTIONS.DISMISS]: {
    title: () => "Dismiss Report(s)",
    description: (title) =>
      `Are you sure you want to dismiss report(s) of ${utilService.replaceNullWithPlaceholder(
        title
      )}?`,
    buttonText: "Dismiss",
    className: "warn-button",
    toggle: false,
  },
  [ACTIVITY_ACTIONS.DELETE_REPORTED_ACTIVITY]: {
    title: () => "Remove Content",
    description: (title) =>
      `Are you sure you want to remove content of ${utilService.replaceNullWithPlaceholder(
        title
      )}?`,
    buttonText: "Remove",
    className: "warn-button",
    toggle: false,
  },
  [ACTIVITY_ACTIONS.BAN_USER]: {
    title: (user) => `Ban:  ${utilService.replaceNullWithPlaceholder(user)}`,
    description: (user) =>
      `Are you sure you want to Ban ${utilService.replaceNullWithPlaceholder(
        user
      )}?`,
    subDescription: (type) => `By doing this, the ${type} will be removed.`,
    buttonText: "Ban",
    className: "warn-button",
    toggle: true,
    initialToggleValue: false,
  },
  [ACTIVITY_ACTIONS.UN_BAN_USER]: {
    title: (user) => `Unban: ${utilService.replaceNullWithPlaceholder(user)}`,
    description: (user) =>
      `Are you sure you want to unban ${utilService.replaceNullWithPlaceholder(
        user
      )}?`,
    buttonText: "Unban",
    className: "unban-button",
  },
  [ADMINISTRATOR_STATUS_ACTION.INACTIVATE]: {
    title: (user) =>
      `Inactivate: ${utilService.replaceNullWithPlaceholder(user)}`,
    description: (user) => `Are you sure you want to Inactivate`,
    buttonText: "Inactivate",
    className: "warn-button",
  },
  [ADMINISTRATOR_STATUS_ACTION.ACTIVATE]: {
    title: (user) =>
      `Activate: ${utilService.replaceNullWithPlaceholder(user)}`,
    description: (user) => `Are you sure you want to Activate`,
    buttonText: "Activate",
    className: "unban-button",
  },
  [FREEBIE_ACTIONS.DELETE_CATEGORY]: {
    title: () => "Delete Freebie",
    subDescription: () =>
      "By doing this, the section will be removed and all the Feebies will no longer be present.",
    description: (freebie) =>
      `This section has ${freebie} amount of Freebies, are you sure you want to delete this section?`,
    buttonText: "Delete",
    className: "warn-button",
    toggle: true,
    initialToggleValue: false,
  },
  [FREEBIE_ACTIONS.DELETE_REPORTED_FREEBIE]: {
    title: () => "Delete Freebie",
    description: (freebie) => `Are you sure you want to delete ${freebie}?`,
    buttonText: "Delete",
    className: "warn-button",
    toggle: true,
  },
  [RESOURCE_ACTIONS.DELETE_CATEGORY]: {
    title: (props) => `Delete Section`,
    subDescription: () =>
      "By doing this, the section will be removed and all the resources will no longer be present.",
    description: (resources) =>
      `This section has ${resources} amount of resources, are you sure you want to delete this section?`,
    buttonText: "Delete",
    className: "warn-button",
    toggle: true,
  },
  [RESOURCE_ACTIONS.ADD_CONFIRM]: {
    title: () => "Add Section",
    description: (catergory) => `Are you sure you want to add ${catergory}?`,
    buttonText: "Add",
    className: "unban-button",
  },
  [RESOURCE_ACTIONS.DELETE_RESOURCE]: {
    title: () => "Delete Resource",
    description: (catergory) => `Are you sure you want to delete ${catergory}?`,
    buttonText: "Delete",
    className: "warn-button",
  },
  [RESOURCE_ACTIONS.UPDATE_CONFIRM]: {
    title: () => "Update Section",
    description: (catergory) => `Are you sure you want to update ${catergory}?`,
    buttonText: "Update",
    className: "unban-button",
  },
  [CHAT_ACTIONS.APPROVE_TOPIC]: {
    title: () => "Approve Suggested Topic",
    description: (topic) => `Are you sure you want to approve ${topic}?`,
    buttonText: "Approve",
    className: "unban-button",
    toggle: false,
  },
  [CHAT_ACTIONS.REJECT_TOPIC]: {
    title: () => "Decline Suggested Topic",
    description: (topic) => `Are you sure you want to decline ${topic}?`,
    buttonText: "Decline",
    className: "warn-button",
    toggle: false,
  },
  [CHAT_ACTIONS.DELETE_TOPIC]: {
    title: () => "Delete Topic",
    description: (topic) => `Are you sure you want to delete ${topic}?`,
    buttonText: "Delete",
    className: "warn-button",
    toggle: false,
  },
  [CHAT_ACTIONS.DELETE_REPORTED_CHAT]: {
    title: () => "Remove Content",
    description: (topic) =>
      `Are you sure you want to delete this ${topic} from the system?`,
    buttonText: "Delete",
    className: "warn-button",
    toggle: false,
  },
  [RESOURCE_ACTIONS.DELETE_V2_CATEGORY]: {
    title: (props) => `Delete Section`,
    subDescription: () =>
      "By doing this, the section will be removed and all the resources will no longer be present.",
    description: (resources) => `Are you sure you want to delete this section?`,
    buttonText: "Delete",
    className: "warn-button",
    toggle: true,
  },
};

export const GET_ACTIVITY_TYPE_TEXT = {
  [ACTIVITY_TYPE.ALL]: "All",
  [ACTIVITY_TYPE.EVENT]: "Event",
  [ACTIVITY_TYPE.PROGRAM]: "Program",
};

export const GET_DESCRIPTION_TEXT_KEY = {
  [ACTIVITY_ACTIONS.BAN_USER]: "ownerName",
  [ACTIVITY_ACTIONS.BAN_USER]: "name",
  [ACTIVITY_ACTIONS.UN_BAN_USER]: "ownerName",
  [ACTIVITY_ACTIONS.UN_BAN_USER]: "name",
  [ACTIVITY_ACTIONS.DELETE]: "title",
  [ACTIVITY_ACTIONS.DELETE]: "name",
  [RESOURCE_ACTIONS.ADD_CONFIRM]: "nameEnglish",
  [ACTIVITY_ACTIONS.DISMISS]: "content.headline",
  [ADMINISTRATOR_STATUS_ACTION.ACTIVATE]: "name",
  [ADMINISTRATOR_STATUS_ACTION.INACTIVATE]: "name",
  [CHAT_ACTIONS.APPROVE_TOPIC]: "topicName",
  [CHAT_ACTIONS.REJECT_TOPIC]: "topicName",
  [CHAT_ACTIONS.DELETE_TOPIC]: "name",
  [ACTIVITY_ACTIONS.DELETE_REPORTED_ACTIVITY]: "content.headline",
  [FREEBIE_ACTIONS.DELETE_REPORTED_FREEBIE]: "content.headline",
  [CHAT_ACTIONS.DELETE_REPORTED_CHAT]: "entityTypeName",
};

export const changeCellColor = (record) => {
  return {};
  // TODO: apply these according to requirement which is not given yet.
  // if (record?.reportDetails?.length > 10) {
  //     return {
  //         props: {
  //             style: { background: "rgba(199, 98, 98, 0.12)" }
  //         }
  //     };
  // } else {
  //     return {};
  // }
};

export const _ACTIVITY_TITLE_KEY = {
  1: "title",
  2: "titleSpanish",
};

export const _ACTIVITY_DESCRIPTION_KEY = {
  1: "description",
  2: "descriptionSpanish",
};
