import React from "react";
import Warning from "../../../assets/images/warning-red.svg";
import dateTimeService from "./../../../util/date-time.service";
import { Badge, Dropdown, Menu, Space, Tooltip } from "antd";
import { MoreOutlined } from "@ant-design/icons";

import utilService from "./../../../util/utils.service";
import "./index.less";
import {
  ACTIVITY_ACTIONS,
  ACTIVITY_TYPE,
  CHAT_ACTIONS,
  FREEBIE_ACTIONS,
  REPORT_TYPE,
} from "../../../constants/constantTypes";

const { formatDateToSpecificTZ, DATE_TIME_FORMAT } = dateTimeService;

export const renderDate = (date, MINUTES) => {
  if (!date) {
    return "--";
  }
  return `${formatDateToSpecificTZ(
    date,
    DATE_TIME_FORMAT.US_DATE_TIME_FORMAT
  )} ${date && "CST"}`;
};

export const renderAction = (record, onActionClick) => {
  if (!record?.actions || record?.actions?.length === 0) {
    return;
  } else if (record.actions.length) {
    return (
      <Dropdown
        overlay={
          <Menu>
            {record?.actions?.map((key, index) => {
              return (
                <Menu.Item
                  onClick={() => {
                    onActionClick(key.code, record);
                  }}
                  key={key.code}
                >
                  {key.name}
                </Menu.Item>
              );
            })}
          </Menu>
        }
        placement="bottomRight"
      >
        <div className="circle-icon" align="center">
          <MoreOutlined className="circle-icon-content" />
        </div>
      </Dropdown>
    );
  }
};

export const renderReason = (selectedRecord, type) => {
  if (type) {
    return (
      <Tooltip title={selectedRecord?.reason}>
        <p style={{ color: "#038fdd" }}>Other</p>
      </Tooltip>
    );
  } else {
    return (
      <p className={`title-ellipsis`} title={selectedRecord?.reason}>
        {selectedRecord?.reason}
      </p>
    );
  }
};

export const contentTitle = (data) => {
  const { content, languageId } = data;
  const _HEADLINE_KEY = {
    1: "headline",
    2: "headlineSpanish",
  };

  const _DESCRIPTION_KEY = {
    1: "description",
    2: "descriptionSpanish",
  };
  return (
    <div>
      <h5
        className="title-ellipsis"
        title={content?.[_HEADLINE_KEY[languageId]]}
      >
        {utilService.replaceNullWithPlaceholder(
          content?.[_HEADLINE_KEY[languageId]]
        )}
      </h5>
      <p
        className="description-ellipsis"
        title={content?.[_DESCRIPTION_KEY[languageId]]}
      >
        {utilService.replaceNullWithPlaceholder(
          content?.[_DESCRIPTION_KEY[languageId]]
        )}
      </p>
    </div>
  );
};

export const reportInfoDetails = (record, setDrawerInfo) => {
  const { reportDetails } = record;
  const [selectedRecord, ...rest] = reportDetails;
  const isOtherType = selectedRecord?.reportType === REPORT_TYPE.OTHER;
  return (
    <div className="cursor" onClick={() => setDrawerInfo(record)}>
      <Space>
        <h5 className="title-ellipsis" title={selectedRecord?.reporterName}>
          {selectedRecord?.reporterName}
        </h5>
        {reportDetails?.length > 1 && (
          <Badge
            className="cursor"
            size="small"
            count={reportDetails?.length}
            onClick={() => setDrawerInfo(record)}
          />
        )}
      </Space>
      {renderReason(selectedRecord, isOtherType)}
    </div>
  );
};

export const _STATUS = {
  [ACTIVITY_ACTIONS.APPROVED]: {
    title: (title) => `Approved ${title}`,
    description: () => "Are you sure you want to approve this activity?",
    buttonText: "Approve",
    className: "confirm-button",
    toggle: false,
  },
  [ACTIVITY_ACTIONS.REJECTED]: {
    title: (title) => `Reject ${title}`,
    description: () => "Are you sure you want to reject this activity?",
    buttonText: "Reject",
    className: "warn-button",
    toggle: false,
  },
  [ACTIVITY_ACTIONS.DELETE]: {
    title: (title) => `Delete ${title}`,
    description: (title) => `Are you sure you want to delete ${title}?`,
    buttonText: "Delete",
    className: "warn-button",
    toggle: true,
  },
  [ACTIVITY_ACTIONS.DISMISS]: {
    title: () => "Dismiss Report(s)",
    description: (title) =>
      `Are you sure you want to dismiss report(s) of ${title}?`,
    buttonText: "Dismiss",
    className: "warn-button",
    toggle: false,
  },
  [ACTIVITY_ACTIONS.REMOVE_CONTENT]: {
    title: () => "Remove Content",
    description: (title) =>
      `Are you sure you want to remove content of ${title}?`,
    buttonText: "Remove",
    className: "warn-button",
    toggle: false,
  },
  [ACTIVITY_ACTIONS.BAN_USER]: {
    title: (user) => `Ban:  ${user}`,
    description: (user) => `Are you sure you want to Ban ${user}?`,
    buttonText: "Ban User",
    className: "warn-button",
  },
  [ACTIVITY_ACTIONS.UN_BAN_USER]: {
    title: (user) => `Unban: ${user}`,
    description: (user) => `Are you sure you want to unban ${user}?`,
    buttonText: "Unban User",
    className: "unban-button",
  },
  [FREEBIE_ACTIONS.DELETE_CATEGORY]: {
    title: () => "Delete Freebie",
    subDescription: () =>
      "By doing this, the section will be removed and all the Feebies will no longer be present.",
    description: (freebie) =>
      `This category has ${freebie} amount of Freebies, are you sure you want to delete this section?`,
    buttonText: "Delete",
    className: "warn-button",
    toggle: true,
  },
  [CHAT_ACTIONS.APPROVE_TOPIC]: {
    title: () => "Approve Suggested Topic",
    description: (topic) => `Are you sure you want to approve ${topic}?`,
    buttonText: "Approve",
    className: "unban-button",
    toggle: false,
  },
  [CHAT_ACTIONS.REJECT_TOPIC]: {
    title: () => "Decline Suggested Topic",
    description: (topic) => `Are you sure you want to decline ${topic}?`,
    buttonText: "Decline",
    className: "warn-button",
    toggle: false,
  },
  [CHAT_ACTIONS.DELETE_TOPIC]: {
    title: () => "Delete Topic",
    description: (topic) => `Are you sure you want to delete ${topic}?`,
    buttonText: "Delete",
    className: "warn-button",
    toggle: false,
  },
};

export const GET_ACTIVITY_TYPE_TEXT = {
  [ACTIVITY_TYPE.ALL]: "All",
  [ACTIVITY_TYPE.EVENT]: "Event",
  [ACTIVITY_TYPE.PROGRAM]: "Program",
};

export const GET_DESCRIPTION_TEXT_KEY = {
  [ACTIVITY_ACTIONS.DELETE]: "title",
  [ACTIVITY_ACTIONS.BAN_USER]: "ownerName",
  [ACTIVITY_ACTIONS.UN_BAN_USER]: "ownerName",
  [ACTIVITY_ACTIONS.DISMISS]: "content.headline",
  [FREEBIE_ACTIONS.DELETE_CATEGORY]: "freebieCount",
  [CHAT_ACTIONS.APPROVE_TOPIC]: "topicName",
  [CHAT_ACTIONS.REJECT_TOPIC]: "topicName",
  [CHAT_ACTIONS.DELETE_TOPIC]: "name",
};

export const topFiveEventDeleted = (data, index) => {
  const { activities } = data;
  return (
    !activities[index] && (
      <div className="gx-flex-row gx-align-items-center">
        <img src={Warning} alt="" width={30} />
        <h4 className="gx-m-0 gx-pl-2" style={{ color: "red" }}>
          Event Deleted
        </h4>
      </div>
    )
  );
};

export const changeCellColor = (data, index) => {
  // TODO: apply these according to requirement which is not given yet.
  // if (record?.reportDetails?.length > 10) {
  //     return {
  //         props: {
  //             style: { background: "rgba(199, 98, 98, 0.12)" }
  //         }
  //     };
  // } else {
  //     return {};
  // }
};

export const mapFormUserValues = (form, eventDetails) => {
  const activities = form.getFieldValue(["activities"]);

  for (let index = 0; index < eventDetails?.activities?.length; index++) {
    activities.push({
      activityDayId: eventDetails?.activities[index]?.activityDayId,
      data: eventDetails?.activities[index],
      rank: index + 1,
      cropImageUrl:
        eventDetails?.activities[index]?.croppedImage?.viewableLink ??
        undefined,
      mediaId: eventDetails?.activities[index]?.croppedImage?.id ?? undefined,
    });
  }

  form.setFieldsValue({
    activities: activities,
  });
};

export const mapFormValues = (form, eventDetails) => {
  const activities = form.getFieldValue(["activities"]);
  const announcement = form.getFieldValue(["announcement"]) ?? {};

  for (let index = 0; index < eventDetails?.activities?.length; index++) {
    activities[index].activityDayId =
      eventDetails?.activities[index]?.activityDayId;
    activities[index].data = eventDetails?.activities[index];
    activities[index].rank = index + 1;
    activities[index].cropImageUrl =
      eventDetails?.activities[index]?.croppedImage?.viewableLink ?? undefined;
    activities[index].mediaId =
      eventDetails?.activities[index]?.croppedImage?.id ?? undefined;
  }
  if (eventDetails?.announcement?.title) {
    announcement.title = eventDetails?.announcement?.title;
    announcement.titleSpanish = eventDetails?.announcement?.titleSpanish;
    announcement.description = eventDetails?.announcement?.description;
    announcement.descriptionSpanish =
      eventDetails?.announcement?.descriptionSpanish;
    announcement.linkTitleEnglish =
      eventDetails?.announcement?.linkTitleEnglish ?? undefined;
    announcement.linkTitleSpanish =
      eventDetails?.announcement?.linkTitleSpanish ?? undefined;
    announcement.linkEnglish =
      eventDetails?.announcement?.linkEnglish ?? undefined;
    announcement.linkSpanish =
      eventDetails?.announcement?.linkSpanish ?? undefined;
    announcement.mediaId = eventDetails?.announcement?.media?.id;
    announcement.announcementImage = eventDetails?.announcement?.media;
  }
  form.setFieldsValue({
    activities: activities,
    announcement: announcement,
  });
};
