import React from "react";
import { useQueryClient } from "react-query";
import { Card, Row, Select, Form, Col, Button } from "antd";

import { RenderChildren } from "./Children";
import { API_STORAGE_KEY } from "../../../../constants/constant";
import NotificationRules from "../../../Lookups/NotificationRules";
import { getSelectedRuleIds, handleConditionChange, handleRule, modifyRules, renderSelection } from "../helper";
import { SELECTION_TYPE, CONNECTED_TYPE, RULE_TYPE, CONDITION_TYPE } from "../../../../constants/constantTypes";

import "./AddNotification.less";

export default function ConditionalRules(props) {
    const { form, getNotificationCount } = props;
    const queryClient = useQueryClient();
    let ruletype = Object.keys(RULE_TYPE);
    const ruleLength = ruletype.length / 2;

    return (
        <Card title="Conditional Rules" className="condition">
            <Form.List name="rules" initialValue={[{}]}>
                {(fields, { add, remove }) => {
                    return (
                        <>
                            {fields.map((field, index) => {
                                return (
                                    <div key={field.key}>
                                        <Row className="gx-flex-row " align="top">
                                            <Form.Item dependencies={[["rules", index, "notificationRuleId"]]} noStyle>
                                                {({ getFieldValue, getFieldsValue, setFieldsValue }) => {
                                                    const rules = getFieldValue(["rules"]);
                                                    const ids = getSelectedRuleIds(form);
                                                    return (
                                                        <Col span={5} className="gx-pl-0">
                                                            {index === 0 && (
                                                                <label className="gx-mb-2 gx-d-block">Rule</label>
                                                            )}
                                                            <Form.Item
                                                                name={[field.name, "notificationRuleId"]}
                                                                key={[field.key, "notificationRuleId"]}
                                                                rules={[
                                                                    { required: true, message: "Rule is required" }
                                                                ]}
                                                                required={false}
                                                            >
                                                                <NotificationRules
                                                                    selectedValues={ids}
                                                                    placeholder="Select"
                                                                    onRuleChange={(value, selectedOption) =>
                                                                        handleRule({
                                                                            value,
                                                                            selectedOption,
                                                                            index,
                                                                            form
                                                                        })
                                                                    }
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    );
                                                }}
                                            </Form.Item>
                                            <Form.Item dependencies={[["rules", index, "notificationRuleId"]]} noStyle>
                                                {({ getFieldValue }) => {
                                                    const conditions =
                                                        getFieldValue(["rules", index, "selectedRule"])?.conditions ||
                                                        [];
                                                    return (
                                                        <>
                                                            <Col span={5}>
                                                                {index === 0 && (
                                                                    <label className="gx-mb-2 gx-d-block">
                                                                        Condition
                                                                    </label>
                                                                )}
                                                                <Form.Item
                                                                    name={[field.name, "notificationConditionId"]}
                                                                    key={[field.key, "notificationConditionId"]}
                                                                    required={false}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: "Condition is required"
                                                                        }
                                                                    ]}
                                                                >
                                                                    <Select
                                                                        placeholder="Select"
                                                                        disabled={
                                                                            !getFieldValue([
                                                                                "rules",
                                                                                index,
                                                                                "notificationRuleId"
                                                                            ])
                                                                        }
                                                                        onChange={(val, option) =>
                                                                            handleConditionChange({
                                                                                val,
                                                                                selectedOption: option?.selectedOption,
                                                                                index,
                                                                                form
                                                                            })
                                                                        }
                                                                        allowClear
                                                                    >
                                                                        {conditions?.map((data) => {
                                                                            return (
                                                                                <Select.Option
                                                                                    key={data?.id}
                                                                                    value={data.id}
                                                                                    selectedOption={data}
                                                                                >
                                                                                    {data?.name}
                                                                                </Select.Option>
                                                                            );
                                                                        })}
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                        </>
                                                    );
                                                }}
                                            </Form.Item>
                                            <Form.Item
                                                dependencies={[
                                                    ["rules", index, "notificationRuleId"],
                                                    ["rules", index, "notificationConditionId"]
                                                ]}
                                                noStyle
                                            >
                                                {({ getFieldValue }) => {
                                                    const selectedConditionType = form.getFieldValue([
                                                        "rules",
                                                        index,
                                                        "conditionType"
                                                    ]);
                                                    const currentRuleType = form.getFieldValue([
                                                        "rules",
                                                        index,
                                                        "ruleType"
                                                    ]);
                                                    const arr = [CONDITION_TYPE.BETWEEN, CONDITION_TYPE.BETWEEN];
                                                    return (
                                                        <Col span={13} className="gx-pr-0">
                                                            {index === 0 && (
                                                                <label className="gx-mb-2 gx-d-block">Value</label>
                                                            )}
                                                            {selectedConditionType === CONDITION_TYPE.BETWEEN ||
                                                            selectedConditionType === CONDITION_TYPE.NOT_BETWEEN ? (
                                                                <RenderChildren
                                                                    index={index}
                                                                    form={form}
                                                                    getNotificationCount={getNotificationCount}
                                                                />
                                                            ) : (
                                                                <Form.Item
                                                                    name={[field.name, "values"]}
                                                                    key={[field.key, "values"]}
                                                                    required={false}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: "Value is required"
                                                                        }
                                                                    ]}
                                                                >
                                                                    {renderSelection(index, form, getNotificationCount)}
                                                                </Form.Item>
                                                            )}
                                                        </Col>
                                                    );
                                                }}
                                            </Form.Item>
                                            <Col
                                                span={1}
                                                className="gx-flex-row gx-align-items-center gx-p-0 "
                                                style={{
                                                    display: "flex !important",
                                                    justifyContent: "flex-end"
                                                }}
                                            >
                                                <Button
                                                    icon={<i className={` usd-delete gx-m-0`} />}
                                                    size="medium"
                                                    className={`noirProRegular gx-fs-md gx-m-0 delete-btn ${
                                                        index === 0 ? "mt-20" : ""
                                                    }`}
                                                    onClick={() => {
                                                        const rules = form.getFieldValue(["rules"]);

                                                        const notificationRuleId = form.getFieldValue([
                                                            "rules",
                                                            index,
                                                            "notificationRuleId"
                                                        ]);
                                                        const notificationConditionId = form.getFieldValue([
                                                            "rules",
                                                            index,
                                                            "notificationConditionId"
                                                        ]);
                                                        const value = form.getFieldValue(["rules", index, "values"]);

                                                        remove(field.name);

                                                        if (
                                                            notificationRuleId &&
                                                            notificationConditionId &&
                                                            (value || value?.length)
                                                        ) {
                                                            if (form.getFieldValue("rules")?.length) {
                                                                getNotificationCount(
                                                                    Object.keys(form.getFieldValue("rules")[0])?.length
                                                                        ? modifyRules(form.getFieldValue("rules"))
                                                                        : []
                                                                );
                                                            }
                                                        }
                                                    }}
                                                    disabled={Boolean(fields?.length < 2)}
                                                ></Button>
                                            </Col>
                                        </Row>
                                    </div>
                                );
                            })}
                            <div className="buttons">
                                {fields.length < ruleLength && (
                                    <Button size="middle" className="add-rule" onClick={() => add({})}>
                                        Add Rule
                                    </Button>
                                )}
                                <Button
                                    type="primary"
                                    className="save-rule"
                                    htmlType="submit"
                                    loading={props?.loading}
                                    disabled={props?.loading}
                                >
                                    Save
                                </Button>
                            </div>
                        </>
                    );
                }}
            </Form.List>
        </Card>
    );
}
