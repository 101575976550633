const postController = "/Post";
const mediaController = "/Media";
const adminController = "/Admin";
const topicController = "/Topic";
const lookupController = "/Lookup";
const portalController = "/Portal";
const accessController = "/Access";
const educationSupportController = "/EducationSupport";
const exploreController = "/Explore";
const accountController = "/Account";
const freebieController = "/Freebie";
const commentController = "/Comment";
const articleCommentController = "/ArticleComment";
const activityController = "/Activity";
const dashboardController = "/Dashboard";
const analyticsController = "/Analytics";
const notificationController = "/Notification";
const neighborhoodController = "/Neighborhood";
const authenticationController = "/Authentication";
const recommendConnectController = "/RecommendConnect";
const childrenActivityController = "/ChildrenActivity";
const announcementController = "/Announcement";
const articleController = "/Article";
const tagController = "/Tag";

export const SERVICE_CONFIG_URLS = {
  ACCOUNT: {
    GET_PROFILE_DETAIL: `${accountController}?userId={userId}`,
    UPDATE_PROFILE_DETAIL: `${accountController}/update`,
    TOGGLE_ACCOUNT_STATUS: `${accountController}/toggle-account-status`,
  },
  EXPLORE: {
    ALL: `${exploreController}/all`,
    DELETE: `${exploreController}/{id}`,
    ORDER: `${exploreController}/order`,
    ADD_CONTENT: `${exploreController}/explore-content`,
    ADD_CATEGORY: `${exploreController}/explore-category`,
    UPDATE_CATEGORY: `${exploreController}/explore-category/{id}`,
  },
  CHILDREN_ACTIVITY: {
    ADD_CHILD_ACTIVITY: `${childrenActivityController}/children-activity`,
    ACTIVITY_CATEGORY: `${childrenActivityController}/children-activity-categories`,
    ACTIVITY: `${childrenActivityController}/children-activity/{id}`,
    UPDATE_ACTIVITY: `${childrenActivityController}/children-activity/{id}`,
    DELETE_CHILDREN_ACTIVITY: `${childrenActivityController}/children-activity/{id}`,
  },
  ACCESS: {
    CATEGORY_LIST: `${accessController}/access-categories-list`,
    DELETE_CONTENT: `${accessController}/access-content/{id}`,
    ACCESS_LISTING: `${accessController}/{id}/children`,
    ALL: `${accessController}/all?type=1`,
    ORDER: `${accessController}/order`,
    ACCESS_ORDER: `${accessController}/access-order`,
    DELETE: `${accessController}/{id}`,
    LISTING: `${accessController}/{id}/children`,
    ADD_CONTENT: `${accessController}/access-content`,
    DELETE_CATEGORY: `${accessController}/category/{id}`,
    UPDATE_CONTENT: `${accessController}/access-content/{id}`,
    ADD_CATEGORY: `${accessController}/access-category`,
    UPDATE_CATEGORY: `${accessController}/access-category/{id}`,
  },
  EDUCATION_SUPPORT: {
    EDUCATION_SUPPORT_LISTING: `${educationSupportController}/{id}/children`,
    ADD_CONTENT: `${educationSupportController}/education-support-content`,
    DELETE_CONTENT: `${educationSupportController}/education-support-content/{id}`,
    UPDATE_CONTENT: `${educationSupportController}/education-support-content/{id}`,
    EDUCATION_SUPPORT_CATEGORY_LIST: `${educationSupportController}/education-support-categories-list`,
  },
  ADMIN: {
    LOGIN: `${adminController}/login`,
    FORGET_PASSWORD: `${adminController}/forget-password`,
    RESET_PASSWORD: `${adminController}/reset-password`,
    SET_PASSWORD: `${adminController}/set-password`,
    LOGOUT: `${adminController}/logout`,
    GET_USER_DETAILS: `${adminController}/me`,
    UPDATE_USER_DETAILS: `${adminController}/update`,
    RESEND_INVITATION: `${adminController}/resend-invite?userId={userId}`,
    SHARE_ACCESS: `${adminController}/share-access`,
    INVITE: `${adminController}/invite`,
    VERIFY_TOKEN: `${adminController}/verifytoken`,
    CHANGE_PASSWORD: `${adminController}/change-password`,
  },
  FREEBIE: {
    DELETE: `${freebieController}/{id}`,
    GET_DETAILS: `${freebieController}/{id}`,
    GET_LISTING: `${freebieController}/list`,
    CREATE: `${freebieController}`,
    UPDATE: `${freebieController}/{id}`,
  },
  ANALYTICS: {
    GET_USERS_DATA: `${analyticsController}/users-data`,
    GET_CONTENT_STATISTICS: `${analyticsController}/content-statistics`,
    PARENTS_VISIT_SECTION: `${analyticsController}/parents-visit-by-section`,
    TIME_SPENT_SECTION: `${analyticsController}/time-spent-by-section`,
    GET_PARENTS_VISITS: `${analyticsController}/parents-visits`,
    TIME_SPENT_APP: `${analyticsController}/time-spent-in-app`,
    TOTAL_USERS_NEIGHBORHOOD: `${analyticsController}/total-users-by-neighborhood`,
    PUSH_NOTIFICATION: `${analyticsController}/push-notification`,
    USER_STATISTIC: `${analyticsController}/user-statistic-report`,
    SEARCH_STATISTIC: `${analyticsController}/search-statistics-report`,
    USER_LOCATIONS_REPORT: `${analyticsController}/user-locations-report`,
  },
  ACTIVITY: {
    UPDATE_ATTEND_ACTIVITY: `${activityController}/attend`,
    VIEW_CENTER: `${activityController}/view-center-details`,
    MY_ACTIVITIES_LIST: `${activityController}/my-activities`,
    ATTEND_ACTIVITIES_LIST: `${activityController}/attending-activities`,
    GET_ACTIVITY_DETAILS: `${activityController}/{activityDayId}?language={language}`,
    ATTEND_ACTIVITY: `${activityController}/attend`,
    DELETE_ACTIVITY: `${activityController}/{entityId}`,
    DELETE_ACTIVITY_DAY: `${activityController}/{activityDayId}`,
    GET_ACTIVITY_LIST: `${activityController}/list`,
    VALIDATE_CONTENT: `${activityController}/validate-content`,
    CREATE_ACTIVITY: `${activityController}`,
    UPDATE_ACTIVITY: `${activityController}/{id}`,
    GET_TOP_EVENTS: `${activityController}/top-5-activities`,
  },
  ANNOUNCEMENT: {
    CREATE: `${announcementController}`,
    READ: `${announcementController}/{id}`,
    UPDATE: `${announcementController}/{id}`,
    DELETE: `${announcementController}/{id}`,
  },
  ARTICLES: {
    CREATE: `${articleController}`,
    READ: `${articleController}/{id}`,
    UPDATE: `${articleController}/{id}`,
    DELETE: `${articleController}/{id}`,
  },
  TAG: {
    GENERATE: `${tagController}/generate-tags`,
  },
  POST: {
    GET_CHAT_LISTING: `${postController}/post-list-admin`,
    GET_POST_DETAILS: `${postController}/{id}`,
    DELETE_POST: `${postController}/{entityId}`,
    DELETE_CHAT: `${postController}/{id}`,
    UPDATE: `${postController}/{id}`,
    CREATE: `${postController}`,
  },
  COMMENT: {
    CREATE: `${commentController}`,
    UPDATE: `${commentController}/{id}`,
    DELETE: `${commentController}/{id}`,
    GET_COMMENT_LIST: `${commentController}/list`,
    GET_COMMENT_DETAILS: `${commentController}/{id}`,
    DELETE_COMMENT: `${commentController}/{entityId}`,
    COMMENT_REPLIES: `${commentController}/replies`,
   
  },
  ARTICLE_COMMENT: {
    REPLIES: `${articleCommentController}/replies`,
    COMMENTS: `${articleCommentController}/list`,
  },
  AUTHENTICATION: {
    BAN_USER: `${authenticationController}/ban/{userId}`,
    DELETE_ACCOUNT: `${authenticationController}/account/{accountId}`,
  },
  EXTERNAL: {
    BASE_URL: "https://maps.googleapis.com",
    PLACE_DETAILS: `/maps/api/geocode/json`,
    AUTOCOMPLETE: "/maps/api/place/autocomplete/json",
  },
  PORTAL: {
    ADD_ACCESS_CATEGORY: `${portalController}/access-category`,
    UPDATE_ACCESS_CATEGORY: `${portalController}/access-category/{id}`,
    DELETE_ACCESS_CATEGORY: `${portalController}/access-category/{id}`,
    ACCESS_CATEGORY_ORDER: `${portalController}/access-category-order`,
    ACTIVITY_LIST: `${portalController}/activities`,
    REPORTED_FREEBIES: `${portalController}/freebie-reports`,
    FREEBIE_CATEGORY: `${portalController}/freebie-categories`,
    REPORTED_ACTIVITIES: `${portalController}/activity-reports`,
    DISMISS_REPORTS: `${portalController}/dismiss-reports/{entityId}`,
    UPDATE_ACTIVITY_STATUS: `${portalController}/{activityId}/approve`,
    FREEBIE_CATEGORY_ORDER: `${portalController}/freebie-category-order`,
    DELETE_FREEBIE_CATEGORY: `${portalController}/freebie-category/{id}`,
    REPORTED_CHATS: `${portalController}/chat-reports`,
    ADMINISTRATORS: `${portalController}/administrators`,
    DELETE_CHAT_TOPIC: `${portalController}/topic/{id}`,
    SUGGESTED_TOPICS: `${portalController}/suggested-topics`,
    APPROVE_TOPIC: `${portalController}/approve-topic/{id}`,
    ADD_TOPIC: `${portalController}/topic`,
    EDIT_TOPIC: `${portalController}/topic/{id}`,
    USERS: `${portalController}/users`,
    NOTIFICATION_LIST: `${portalController}/notifications`,
    COMMUNITY_PARTNERS: `${portalController}/community-partners`,
    UPDATE_COMMUNITY_PARTNER: `${portalController}/reject-community-partner/{id}`,
    MODIFY_TOP_ACTIVITIES: `${portalController}/top-5-activities`,
    TOP_FIVE_ANNOUNCEMENT_LIST: `${portalController}/top5-announcement-list`,
    ADD_TOP_FIVE_ANNOUNCEMENT: `${portalController}/top5-announcement`,
    UPDATE_TOP_FIVE_ANNOUNCEMENT: `${portalController}/top5-announcement`,
    GET_TOP_FIVE_ANNOUNCEMENT: `${portalController}/top5-announcement-date`,
    TOP_EVENT_LIST: `${portalController}/top-5-activities/list`,
    GET_TOP_EVENTS: `${portalController}/top-5-activities`,
    ADD_CONNECT_CATEGORY: `${portalController}/recommend-connect-category`,
    UPDATE_CONNECT_CATEGORY: `${portalController}/recommend-connect-category/{id}`,
    DELETE_CONNECT_CATEGORY: `${portalController}/recommend-connect-category/{id}`,
    CHANGE_CATEGORY_ORDER: `${portalController}/recommend-connect-category-order`,
    ADD_ACTIVITY_CATEGORY: `${portalController}/children-activity-category`,
    UPDATE_ACTIVITY_CATEGORY: `${portalController}/children-activity-category/{id}`,
    DELETE_ACTIVITY_CATEGORY: `${portalController}/children-activity-category/{id}`,
    CHANGE_ACTIVITY_CATEGORY_ORDER: `${portalController}/children-activity-category-order`,
    CHILDREN_ACTIVITIES: `${portalController}/children-activities`,
    ANNOUNCEMENT_LISTING: `${portalController}/announcement-list`,
    ARTICLES_LISTING: `${portalController}/article-list`,
    GET_TOP_FIVE_ACTIVITIES_BY_USER: `${portalController}/top-5-activities/{accountId}/list`,
    ADD_EDUCATION_SUPPORT_CATEGORY: `${portalController}/education-support-category`,
    UPDATE_EDUCATION_SUPPORT_CATEGORY: `${portalController}/education-support-category/{id}`,
    DELETE_EDUCATION_SUPPORT_CATEGORY: `${portalController}/education-support-category/{id}`,
    EDUCATION_SUPPORT_CATEGORY_ORDER: `${portalController}/education-support-category-order`,
  },
  DASHBOARD: {
    ACTIVITY_REPORTS: `${dashboardController}/activity-reports`,
    CHAT_REPORTS: `${dashboardController}/chat-reports`,
    RECENT_ACTIVITIES: `${dashboardController}/recent-activities`,
    FREEBIE_REPORTS: `${dashboardController}/freebie-reports`,
    GRAPH: `${dashboardController}/graph`,
  },
  LOOKUP: {
    TOPIC: `${lookupController}/topic?type={type}`,
    NEIGHBORHOODS: `${lookupController}/neighborhood`,
    GET_CHAT_AVATAR: `${lookupController}/chat-avatar`,
    CHILD_NEEDS: `${lookupController}/special-need`,
    AVATAR: `${lookupController}/avatar?type={type}`,
    CHILD_INTEREST: `${lookupController}/child-interest`,
    PARENT_INTEREST: `${lookupController}/parent-interest`,
    FREEBIE_CATEGORY: `${lookupController}/freebie-category`,
    TRANSPORTATION: `${lookupController}/transportation`,
    ACCOMMODATION: `${lookupController}/accomodation`,
    GENERAL_TAG: `${lookupController}/general-tag`,
    GET_SEARCH_ACTIVITES: `${lookupController}/activities`,
    GET_SEARCH_FREEBIE: `${lookupController}/freebies`,
    GET_SEARCH_POSTS: `${lookupController}/posts`,
    GET_SEARCH_EXPLORE: `${lookupController}/explore-resource`,
    GET_SEARCH_ACCESS: `${lookupController}/access-resource`,
    GET_AUTHORS: `${lookupController}/authors`,
  },
  NEIGHBORHOOD: {
    FIND: `${neighborhoodController}/find`,
  },
  MEDIA: {
    UPLOAD_LOGO: `${mediaController}/uploadlogo`,
    UPLOAD_MEDIA: `${mediaController}/uploadmedia`,
    DUPLICATE_MEDIA: `${mediaController}/duplicate`,
    UPLOAD_PROFILE: `${mediaController}/uploadprofile`,
    UPLOAD_RESOURCE: `${mediaController}/uploadresource`,
    REMOVE_PROFILE_PICTURE: `${mediaController}/removeprofile`,
  },
  NOTIFICATION: {
    ADD_NOTIFICATION: `${notificationController}`,
    UPDATE_NOTIFICATION: `${notificationController}/{id}`,
    NOTIFICATION_DETAILS: `${notificationController}/{id}`,
    DELETE_NOTIFICATION: `${notificationController}/{id}`,
    NOTIFICATION_USER_COUNT: `${notificationController}/notification-user-count`,
  },
  LOOKUP: {
    ACCESS: `${lookupController}/access`,
    EXPLORE: `${lookupController}/explore`,
    TOPIC: `${lookupController}/topic?type={type}`,
    NEIGHBORHOODS: `${lookupController}/neighborhood`,
    GET_CHAT_AVATAR: `${lookupController}/chat-avatar`,
    CHILD_NEEDS: `${lookupController}/special-need`,
    AVATAR: `${lookupController}/avatar?type={type}`,
    CHILD_INTEREST: `${lookupController}/child-interest`,
    PARENT_INTEREST: `${lookupController}/parent-interest`,
    FREEBIE_CATEGORY: `${lookupController}/freebie-category`,
    TRANSPORTATION: `${lookupController}/transportation`,
    ACCOMMODATION: `${lookupController}/accomodation`,
    GENERAL_TAG: `${lookupController}/general-tag`,
    TIMEZONE: `${lookupController}/timezone`,
    ISDS: `${lookupController}/isds`,
    NOTIFICATION_RULES: `${lookupController}/notification-rules`,
    RECOMMEND_CONNECT: `${lookupController}/recommend-connect`,
    GET_SEARCH_ACTIVITES: `${lookupController}/activities`,
    GET_SEARCH_FREEBIE: `${lookupController}/freebies`,
    GET_SEARCH_POSTS: `${lookupController}/posts`,
    GET_SEARCH_EXPLORE: `${lookupController}/explore-resource`,
    GET_SEARCH_ACCESS: `${lookupController}/access-resource`,
    GET_TOP_FIVE_LIST: `${lookupController}/top-5-activities`,
    GET_CATEGORY_MEDIA: `${lookupController}/category-media`,
    GET_ACTIVITY_TOPIC: `${lookupController}/activity-topics`,
    GET_CHILDREN_ACTIVITIES: `${lookupController}/children-activities`,
    GET_AUTHORS: `${lookupController}/authors`,
    GET_USERS: `${lookupController}/user`,
  },
  RECOMMEND_CONNECT: {
    COMMUNITY_PARTNER_LIST: `${recommendConnectController}/list`,
    ADD_COMMUNITY_PARTNER: `${recommendConnectController}/community-partner`,
    UPDATE_COMMUNITY_PARTNER: `${recommendConnectController}/community-partner/{id}`,
    DELETE_COMMUNITY_PARTNER: `${recommendConnectController}/community-partner/{id}`,
    COMMUNITY_PARTNER_DETAILS: `${recommendConnectController}/{id}`,
    CONNECT_CATEGORIES: `${recommendConnectController}/recommend-connect-category-list`,
  },
  TOPIC: {
    ALL_TOPICS: `${topicController}/list`,
  },
};

export const multiFormDataHeader = {
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "text/plain",
  },
};
