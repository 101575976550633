import React from "react";
import { Image } from "antd";
import "./ImageList.less";
import Remove from "../../assets/images/iconclose.svg";

export default function ImageList({
  children,
  onRemove,
  data,
  showPreview,
  className = "",
  ...props
}) {

    console.log(data, '../')
  return (
    <>
      <div className="flexRow">
        {data?.map((item, index) => {
          return (
            <React.Fragment key={item?.id ?? index}>
              
              <Image
                className={`image ${className}`}
                src={
                  item?.viewableLink ||
                  
                  (item.originFileObj
                    ? URL.createObjectURL(item.originFileObj)
                    : "")
                }
                preview={showPreview}
              />
              <Image
                className="remove-icon"
                src={Remove}
                preview={false}
                width={20}
                onClick={() => onRemove(item?.uid)}
              />
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
}
